@tailwind base;
@tailwind components;
@tailwind utilities;

.font-inter {
  font-family: 'Inter', sans-serif;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.animate-width {
  -webkit-transition: width 0.1s ease-in-out;
  -moz-transition: width 0.1s ease-in-out;
  -o-transition: width 0.1s ease-in-out;
  transition: width 0.1s ease-in-out;
}
